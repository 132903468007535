export enum TicketType {
    ticketId = 'T',
    hopId = 'H',
    subId = 'S',
    ticketName = 'ENABLE_TICKET',
    hopName = 'ENABLE_HOPONHOPOFF',
    subName = 'ENABLE_SUBSCRIPTION',
    ticketValue = 'Ticket',
    hopValue = 'Hop-On Hop-Off',
    subValue = 'Abbonamento'
}

export enum RoundTrip {
    oneId = 'ONE',
    roundId = 'ROUNDTRIP',
    openId = 'OPEN',
    oneName = 'ROUNDTRIP_ONEWAY',
    roundName = 'ROUNDTRIP_TWOWAY',
    openName = 'ROUNDTRIP_OPEN',
    oneValue = 'Sola Andata',
    roundValue = 'Andata/Ritorno',
    openValue = 'Andata/Ritorno (data aperta)'
}

export enum SaleMode {
    standardId = '1',
    customerId = '2',
    standardName = 'SALE_STANDARD',
    customerName = 'SALE_CUSTOMER',
    standardValue = 'Vendita Standard',
    customerValue = 'Vendita Cliente'
}

export enum TicketCategoryType {
    categoriesGroup = 'categories-grouped',
    category = 'category'
}

export enum TicketData {
    minimalId = 'MIN',
    fullId = 'FULL',
    minimalName = 'TICKET_DATA_MINIMAL',
    fullName = 'TICKET_DATA_FULL',
    minimalValue = 'Modalità Minima',
    fullValue = 'Modalità Completa'
}

export enum ValidationStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    ISSUING = 'ISSUING',
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    BLOCKED = 'BLOCKED',
    RENEWING = 'RENEWING',
    REPLACING = 'REPLACING',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED'
  }